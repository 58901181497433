import { media } from 'gatsby-theme-docz/src/theme/breakpoints'
import * as styles from 'gatsby-theme-docz/src/components/Header/styles'

export const wrapper = {
  ...styles.wrapper,
  display: "flex",
  alignItems: "center",
  margin: 24,
  borderRadius: 4,
  '> *:last-child': {
    flex: 1,
  },
  '+ div': {
    minHeight: "unset"
  },
  [media.tablet]: {
    marginBottom: 0,
  }
}

export const innerContainer = styles.innerContainer

export const menuIcon = {
  ...styles.menuIcon,
  position: 'relative',
  inset: 'auto',
  [media.tablet]: {
    display: 'block',
  },
}

export const menuButton = {
  ...styles.menuButton,
  opacity: 1,
  padding: 20,
  marginRight: -20
}

export const headerButton = styles.headerButton

export const editButton = styles.editButton