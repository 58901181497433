import baseGlobal from 'gatsby-theme-docz/src/theme/global'
import { merge } from 'lodash';

export default merge(baseGlobal, {
  '*': {
    "WebkitFontSmoothing": "antialiased",
    "MozOsxFontSmoothing": "grayscale"
  },
  '@font-face': {
    fontFamily: 'Sneak Bold',
    src: "url('/public/sneak/Sneak Bold Web/Sneak-Bold.woff2') format('woff2');",
  },
  'h1, h2, h3, h4, h5, h6, strong': {
    fontWeight: 'normal'
  },
  'strong': {
    fontFamily: 'Sneak Bold',
  },
  'a': {
    color: 'inherit'
  },
})