export const white = '#FFFFFF'
export const grayUltraLight = '#FCFBFA'
export const grayExtraLight = '#F5F6F7'
export const grayLight = '#CED4DE'
export const gray = '#67788a'
export const grayDark = '#353535'
export const grayExtraDark = '#171717'
export const dark = '#111'
export const blueLight = '#e9f2fa'
export const blue = '#0B5FFF'
export const skyBlue = '#1FB6FF'
export const negative = '#EB4D4B'