import { media } from 'gatsby-theme-docz/src/theme/breakpoints'
import * as styles from 'gatsby-theme-docz/src/components/Sidebar/styles'
import { merge } from 'lodash';

export const global = styles.global

export const overlay = ({ open }) => merge(
  styles.overlay({ open }),
  { top: 0 }
);

export const wrapper = ({ open }) => merge(
  styles.wrapper({ open }),
  {
    [media.tablet]: {
      top: 0,
    },
  }
)