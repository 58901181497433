import baseStyles from 'gatsby-theme-docz/src/theme/styles'
import { merge } from 'lodash';

/*
I don't know how create multiple font-face rules in gloabal.js,
so this is just a weird but effective work-around
*/

export default merge(baseStyles, {
  Container: {
    '@font-face': {
      fontFamily: 'Sneak Medium',
      src: "url('/public/sneak/Sneak Medium Web/Sneak-Medium.woff2') format('woff2');",
    },
  },
  root: {
    '@font-face': {
      fontFamily: 'Sneak Regular',
      src: "url('/public/sneak/Sneak Regular Web/Sneak-Regular.woff2') format('woff2');",
    },
  },
})