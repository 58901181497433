import baseTheme from 'gatsby-theme-docz/src/theme/index'
import { merge } from 'lodash';
import * as colors from './colors';

export default merge(baseTheme, {
  colors: {
    header: {
      bg: colors.dark,
      text: colors.white,
      button: {
        bg: colors.white,
        color: colors.dark,
      }
    },
    modes: {
      dark: {
        header: {
          bg: colors.white,
          text: colors.dark,
          button: {
            bg: colors.dark,
            color: colors.white,
          }
        }
      }
    }
  },
  fonts: {
    body: 'Sneak Regular',
    heading: 'Sneak Medium',
  },
  fontWeights: {
    body: 'normal',
    heading: 'normal',
    bold: 'normal',
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 70, 100],
})