/** @jsx jsx */
import { jsx } from "theme-ui";
import { Fragment } from "react";
const protectedRoutes = ["/code-challenge", "/server-setup", "/ssh-keys-setup"];

export const isProtectedRoute = (path) =>
  protectedRoutes.find((route) => path.startsWith(route));

export const PrivateIcon = ({ route }) =>
  isProtectedRoute(route) ? <Fragment>🔒&nbsp;&nbsp;</Fragment> : null;
